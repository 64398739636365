<script>
import axios from "axios";
import Qs from "qs";
import * as qiniu from "qiniu-js";

import {
  K_DOMAIN,
  K_QINIU_IMAGE_SMALL_END_200_SUFFIX,
  K_QINIU_IMAGE_SMALL_END_100_PX_SUFFIX,
  K_NAME,
  K_PREVIEW_DOMAIN,
  K_VERSION,
  K_QINIU_DOMAIN,
  K_PROJECT_NAME,
} from "../../public/static/js/config";
import storage from "../../public/static/js/storage";

const domain = K_DOMAIN;
const WEBSITE_NAME = "SPACEMANS后台管理" + K_NAME;
const preview_domain = K_PREVIEW_DOMAIN;

const K_QINIU_IMAGE_SMALL_END_200 = K_QINIU_IMAGE_SMALL_END_200_SUFFIX;
const K_QINIU_IMAGE_SMALL_END_100_PX = K_QINIU_IMAGE_SMALL_END_100_PX_SUFFIX;

const Copywriter = "spacemans_copywriter";
const LotteryPath = "/lottery/draw/";
const CommonStatusList = [
  {
    id: 0,
    name: "所有",
    tag: "",
  },
  {
    id: 1,
    name: "已发布",
    tag: "success",
  },
  {
    id: 2,
    name: "审核中",
    tag: "warning",
  },
  {
    id: 3,
    name: "已失效",
    tag: "danger",
  },
];

const StatusSelectList = [
  {
    id: 1,
    name: "已发布",
    tag: "success",
  },
  {
    id: 2,
    name: "审核中",
    tag: "warning",
  },
  {
    id: 3,
    name: "已失效",
    tag: "danger",
  },
];
const reedemCommonStatusList = [
  {
    id: 0,
    name: "全部",
    tag: "",
  },
  {
    id: 1000,
    name: "未使用",
    tag: "warning",
  },
  {
    id: 2000,
    name: "使用中",
    tag: "success",
  },
  {
    id: 3000,
    name: "已使用",
    tag: "info",
  },
  {
    id: 4000,
    name: "无效",
    tag: "danger",
  },
];
const reedemStatusList = [
  {
    id: 1000,
    name: "未使用",
    tag: "warning",
  },
  {
    id: 2000,
    name: "使用中",
    tag: "success",
  },
  {
    id: 3000,
    name: "已使用",
    tag: "info",
  },
  {
    id: 4000,
    name: "无效",
    tag: "danger",
  },
];

Date.prototype.Format = function (fmt, local, utc) {
  let tempTime = this;
  let o = {};
  if (utc) {
    o = {
      "y+": tempTime.getUTCFullYear(),
      "M+": tempTime.getUTCMonth() + 1, //月份
      "d+": tempTime.getUTCDate(), //日
      "h+": tempTime.getUTCHours(), //小时
      "m+": tempTime.getUTCMinutes(), //分
      "s+": tempTime.getUTCSeconds(), //秒
      "q+": Math.floor((tempTime.getUTCMonth() + 3) / 3), //季度
      "S+": tempTime.getMilliseconds(), //毫秒
    };
  } else {
    o = {
      "y+": tempTime.getFullYear(),
      "M+": tempTime.getMonth() + 1, //月份
      "d+": tempTime.getDate(), //日
      "h+": tempTime.getHours(), //小时
      "m+": tempTime.getMinutes(), //分
      "s+": tempTime.getSeconds(), //秒
      "q+": Math.floor((tempTime.getMonth() + 3) / 3), //季度
      "S+": tempTime.getMilliseconds(), //毫秒
    };
  }

  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      if (k == "y+") {
        fmt = fmt.replace(RegExp.$1, ("" + o[k]).substr(4 - RegExp.$1.length));
      } else if (k == "S+") {
        var lens = RegExp.$1.length;
        lens = lens == 1 ? 3 : lens;
        fmt = fmt.replace(
          RegExp.$1,
          ("00" + o[k]).substr(("" + o[k]).length - 1, lens)
        );
      } else {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
  }
  if (local) {
    //获取时区
    let timeOff = new Date().getTimezoneOffset() / 60;
    let sign = Math.sign(timeOff);
    let zone = ("00" + Math.abs(timeOff) + "00").substr(
      ("" + Math.abs(timeOff)).length
    );
    zone = sign === -1 ? "+" + zone.toString() : "-" + zone.toString();
    fmt = fmt + " " + zone;
  }
  return fmt;
};

let token = "";

function getHttpConfigs() {
  return {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: getToken(),
    },
  };
}

function getFileConfigs() {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: getToken(),
    },
  };
}
function getFileConfigsBlob() {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: getToken(),
    },
    responseType: "blob",
  };
}

function isAuthenticated() {
  return getToken() != null && getToken() != "";
}

function toggleLoader(type) {
  document.getElementById("loader-overlay").style.display = type
    ? "block"
    : "none";
}

// eslint-disable-next-line
function httpGet(url, params) {
  let paramsString = params ? requestParams(params) : "";
  return new Promise((resolve, reject) => {
    axios.get(domain + url + paramsString, getHttpConfigs()).then(
      (res) => {
        resolve(res.data);
      },
      (err) => {
        reject(err.response.data);
      }
    );
  });
}

function httpPost(url, params, { ...extraProps } = {}) {
  const { responseType = "json", isAllData = false } = extraProps;
  // 访问网络
  return new Promise((resolve, reject) => {
    axios({
      headers: getHttpConfigs().headers,
      method: "post",
      url: domain + url,
      data: Qs.stringify(params, { arrayFormat: "repeat" }),
      responseType: responseType,
    }).then(
      (res) => {
        if (isAllData) {
          resolve(res);
        } else {
          resolve(res.data);
        }
      },
      (err) => {
        let response =
          err.response && err.response.data ? err.response.data : err;
        if (responseType === "arraybuffer") {
          const enc = new TextDecoder("utf-8");
          response = JSON.parse(enc.decode(new Uint8Array(response)));
        }
        if (response.code === "1000007") {
          //  未授权
          removeGlobalStorage("_user");
          window.location.href = "/";
          reject(response.message);
        } else {
          reject(response);
        }
      }
    );
  });
}
function httpPostBlob(url, params) {
  // 访问网络
  return new Promise((resolve, reject) => {
    axios({
      responseType: "blob",
      headers: getHttpConfigs().headers,
      method: "post",
      url: domain + url,
      data: Qs.stringify(params, { arrayFormat: "repeat" }),
    }).then(
      (res) => {
        // resolve(res.data);
        resolve(res);
      },
      (err) => {
        reject(err.response.data);
      }
    );
  });
}

function httpFilePost(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(domain + url, data, getFileConfigs()).then(
      (res) => {
        resolve(res.data);
      },
      (err) => {
        reject(err.response.data);
      }
    );
  });
}

function httpGetFile(url, params) {
  let paramsString = params ? requestParams(params) : "";
  return new Promise((resolve, reject) => {
    axios.get(url + paramsString, getFileConfigs()).then(
      (res) => {
        resolve(res.data);
      },
      (err) => {
        reject(err.response.data);
      }
    );
  });
}
function httpGetFileBlob(url, params) {
  let paramsString = params ? requestParams(params) : "";
  return new Promise((resolve, reject) => {
    axios.get(url + paramsString, getFileConfigsBlob()).then(
      (res) => {
        resolve(res.data);
      },
      (err) => {
        reject(err.response.data);
      }
    );
  });
}
function requestParams(obj) {
  let rangeArr = [],
    params = "";
  if (obj && typeof obj === "object") {
    if (rangeArr.length === 0) {
      rangeArr.push("?");
    }
    for (let i in obj) {
      if (obj.hasOwnProperty(i)) {
        let value = obj[i];
        if (Object.prototype.toString.call(value) === "[object Array]") {
          if (value !== []) {
            for (let j in value) {
              rangeArr.push(i);
              rangeArr.push("=");
              rangeArr.push(value[j]);
              rangeArr.push("&");
            }
          }
        } else {
          if (value !== "") {
            rangeArr.push(i);
            rangeArr.push("=");
            rangeArr.push(value);
            rangeArr.push("&");
          }
        }
      }
    }
    params = rangeArr.join("").replace(/&$/, "");
    return params;
  }
}

// 时间转为字符串加上时区
function formatTimestampLongZone(time) {
  let timestamp = new Date(time).getTime() / 1000;
  if (timestamp > 0) {
    let date = new Date(timestamp * 1000);
    let Y = date.getFullYear() + "-";
    let M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    let HH =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    let MM =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
    let SS =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

    //获取时区
    let timeOff = new Date().getTimezoneOffset() / 60;
    let sign = Math.sign(timeOff);
    let zone = ("00" + Math.abs(timeOff) + "00").substr(
      ("" + Math.abs(timeOff)).length
    );
    zone = sign === -1 ? "+" + zone.toString() : "-" + zone.toString();
    // fmt = fmt + ' ' + zone;

    return Y + M + D + " " + HH + MM + SS + " " + zone;
  } else {
    return "";
  }
}

// 时间格式处理
function formatTimesToTime(time) {
  let timestamp = new Date(time).getTime() / 1000;
  if (timestamp > 0) {
    let date = new Date(timestamp * 1000);
    let Y = date.getFullYear() + "-";
    let M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    let HH =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    let MM =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
    let SS =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

    return Y + M + D + " " + HH + MM + SS;
  } else {
    return "";
  }
}

// 时间转时间戳 delay 直接记为秒
function formatTime(time, delay) {
  let timestamp = new Date(time).getTime() / 1000;
  if (delay) {
    timestamp = timestamp + delay;
  }
  return String(timestamp) + ".000";
}

function getDatetimeFromTime(timestampMilliseconds, suffix) {
  let timestamp = timestampMilliseconds / 1000;
  if (timestamp > 0) {
    let date = new Date(timestamp * 1000);
    let Y = date.getFullYear() + "-";
    let M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    let HH, MM, SS;

    if (suffix === "begin") {
      HH = "08:";
      MM = "00:";
      SS = "00";
    } else if (suffix === "end") {
      HH = "07:";
      MM = "59:";
      SS = "59";
    } else if (suffix === "now") {
      HH =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      MM =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      SS = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    }
    return Y + M + D + " " + HH + MM + SS;
  } else {
    return "";
  }
}

function isInArray(arr, value) {
  for (let i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return { isIn: true, index: i };
    }
  }
  return { isIn: false, index: 0 };
}

function delInArray(value, arr) {
  let index = arr.indexOf(value);
  arr.splice(index, 1);
  return arr;
}

function isNumber(value) {
  return !Number.isNaN(Number(value));
}

function getNextMonth(date) {
  var arr = date.split("-");
  var year = arr[0]; //获取当前日期的年份
  var month = arr[1]; //获取当前日期的月份
  var day = arr[2]; //获取当前日期的日
  //var days = new Date(year, month, 0);
  //days = days.getDate(); //获取当前日期中的月的天数
  var year2 = year;
  var month2 = parseInt(month) + 1;
  if (month2 == 13) {
    year2 = parseInt(year2) + 1;
    month2 = 1;
  }
  var day2 = day;
  var days2 = new Date(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = "0" + month2;
  }

  var t2 = year2 + "-" + month2 + "-" + day2;
  return t2;
}

function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) c_end = document.cookie.length;
      return decodeURIComponent(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

function getWebpSrc(curSrc, config) {
  // 判断所属
  if (curSrc && curSrc.indexOf("https://res.spacemans") > -1) {
    let handlePrefix = "?imageMogr2";
    // 未经处理
    if (curSrc && curSrc.indexOf(handlePrefix) === -1) {
      // 浏览器支持
      if (getCookie("_wp")) {
        let webpFormat = "/format/webp";
        // 判断格式
        if (
          curSrc.indexOf("jpg") > -1 ||
          curSrc.indexOf("png") > -1 ||
          curSrc.indexOf("jpeg") > -1
        ) {
          curSrc += handlePrefix;
          curSrc += webpFormat;
          if (config) {
            if (config.suffix && config.suffix !== "") {
              curSrc += config.suffix;
            }
          }
        }
      } else {
        if (curSrc.indexOf("webp") > -1) {
          curSrc += "?imageMogr2/format/png";
        } else if (config) {
          if (config.suffix && config.suffix !== "") {
            // 判断格式
            if (
              curSrc.indexOf("jpg") > -1 ||
              curSrc.indexOf("png") > -1 ||
              curSrc.indexOf("jpeg") > -1
            ) {
              curSrc = curSrc + "?imageMogr2" + config.suffix;
            }
          }
        }
      }
    }
  }
  return curSrc;
}

function getToken() {
  if (token == null || token == "") {
    token = getGlobalStorage("_user") ? getGlobalStorage("_user").token : "";
  }
  return token;
}

let globalStorage = storage;

function setGlobalStorage(key, value, expired) {
  if (value && value !== undefined) {
    return globalStorage.set(getGlobalStorageKeyName(key), value, expired);
  }
}

function getGlobalStorage(key) {
  return globalStorage.get(getGlobalStorageKeyName(key));
}

function removeGlobalStorage(key) {
  return globalStorage.remove(getGlobalStorageKeyName(key));
}

function getGlobalStorageKeyName(key) {
  //  项目 + 版本号 + key
  return K_PROJECT_NAME + "::" + K_VERSION + "::" + key;
}

// 复制信息方法
function copyText(text) {
  let textarea = document.createElement("input"); //创建input对象
  let currentFocus = document.activeElement; //当前获得焦点的元素
  document.body.appendChild(textarea); //添加元素
  textarea.value = text;
  textarea.focus();
  if (textarea.setSelectionRange) {
    textarea.setSelectionRange(0, textarea.value.length); //获取光标起始位置到结束位置
  } else {
    textarea.select();
  }

  let flag = false;
  try {
    flag = document.execCommand("copy"); //执行复制
  } catch (eo) {
    flag = false;
  }
  document.body.removeChild(textarea); //删除元素
  currentFocus.focus();
  return flag;
}

/* ------ 七牛上传图片 ---------------------------------------------------------------------------- */
function qiniuUploadImage(file, key, progress, callback) {
  // 获取Key
  let params = {};
  httpPost("/api/qiniu/get/token", params)
    .then((res) => {
      if (res.code === "1000000") {
        let qiniu_key = res.data;
        let putExtra = {
          fname: file.name,
          params: {},
          // mimeType: ["image/png", "image/jpeg", "image/gif"]
        };
        let observe = {
          // eslint-disable-next-line no-unused-vars
          next(res) {
            // console.log('已上传大小，单位为字节：' + res.total.loaded)
            // console.log('本次上传的总量控制信息，单位为字节：' + res.total.size)
            // console.log('当前上传进度，范围：0～100：' + res.total.percent);
            progress(res);
          },
          error(err) {
            callback(err.message, err);
          },
          complete(res) {
            //完成后的操作
            //上传成功以后会返回key 和 hash  key就是文件名了！
            callback(undefined, res);
          },
        };

        let qiniuConfigs = {
          useCdnDomain: true,
          region: qiniu.region.na0,
        };

        //开始上传  token 需要找后端获取(单独的方法)
        let observable = qiniu.upload(
          file,
          key,
          qiniu_key,
          putExtra,
          qiniuConfigs
        );
        // eslint-disable-next-line
        let subscription = observable.subscribe(observe);
      } else {
        callback(res.message, undefined);
      }
    })
    .catch((err) => {
      // console.log("获取Token出现错误", err)
      callback(err, undefined);
    });
}

function tableStyle() {
  return "text-align:center";
}

// 二进制流下载（httpDownload，downloadFile）
function httpDownload(url, params) {
  return new Promise((resolve, reject) => {
    axios({
      headers: getHttpConfigs().headers,
      method: "post",
      url: domain + url,
      data: Qs.stringify(params, { arrayFormat: "repeat" }),
      responseType: "arraybuffer",
    }).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        const enc = new TextDecoder("utf-8");
        const response =
          err.response && err.response.data ? err.response.data : err;
        const originResponse = JSON.parse(enc.decode(new Uint8Array(response)));
        if (originResponse.code === "1000007") {
          //  未授权
          removeGlobalStorage("_user");
          window.location.href = "/";
        }
        reject(originResponse);
      }
    );
  });
}
const downloadFile = (res, type, defaultFilename) => {
  // 创建blob对象，解析流数据
  const blob = new Blob([res.data], {
    // 设置返回的文件类型
    type: type,
  });
  // 这里就是创建一个a标签，等下用来模拟点击事件
  const a = document.createElement("a");
  // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
  const URL = window.URL || window.webkitURL;
  // 根据解析后的blob对象创建URL 对象
  const herf = URL.createObjectURL(blob);
  // 下载链接
  a.href = herf;
  let filename = "";
  const contentDispositionHeader = res.headers["content-disposition"];
  if (contentDispositionHeader) {
    try {
      const filenameMatches =
        contentDispositionHeader.match(/filename\*=([^;]+)/);
      if (filenameMatches) {
        const [, encodedFilename] = filenameMatches;
        const [, charset, encodedName] = encodedFilename.split("'");
        filename = decodeURIComponent(encodedName);
      }
    } catch (error) {}
  }
  if (!filename) {
    const timestamp = new Date().toISOString().split("T")[0]; // 获取当前日期，格式为YYYY-MM-DD
    const fileExtension = ".xlsx"; // 设置文件扩展名
    filename = `${defaultFilename}_${timestamp}${fileExtension}`;
  }
  a.download = filename;
  document.body.appendChild(a);
  // 点击a标签，进行下载
  a.click();
  // 收尾工作，在内存中移除URL 对象
  document.body.removeChild(a);
  window.URL.revokeObjectURL(herf);
};

export default {
  WEBSITE_NAME,
  preview_domain,
  domain,
  K_QINIU_IMAGE_SMALL_END_200,
  K_QINIU_IMAGE_SMALL_END_100_PX,
  K_QINIU_DOMAIN,
  Copywriter,
  LotteryPath,
  CommonStatusList,
  StatusSelectList,
  reedemCommonStatusList,
  reedemStatusList,
  httpPost,
  httpFilePost,
  toggleLoader,
  isAuthenticated,
  formatTime,
  formatTimesToTime,
  formatTimestampLongZone,
  getDatetimeFromTime,
  isNumber,
  isInArray,
  delInArray,
  getNextMonth,
  setGlobalStorage,
  getGlobalStorage,
  removeGlobalStorage,
  qiniuUploadImage,
  httpGet,
  getWebpSrc,
  copyText,
  getGlobalStorageKeyName,
  tableStyle,
  httpGetFile,
  httpGetFileBlob,
  httpPostBlob,
  httpDownload,
  downloadFile,
};
</script>
