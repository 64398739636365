<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
};
</script>

<style>
html,
body,
#app,
.el-container {
  /*设置内部填充为0，几个布局元素之间没有间距*/
  padding: 0px;
  /*外部间距也是如此设置*/
  margin: 0px;
  /*统一设置高度为100%*/
  height: 100%;
  /*统一设置宽度为100%*/
  width: 100%;
  /*其他设置*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*文字居中显示*/
  text-align: center;
  color: #2c3e50;
}

body {
  font-family: system-ui;
}

/* #app {
    background: url(./assets/login.jpg);
  background-size: 100% 150%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: center;
  background-attachment: fixed;
} */

.header-long-global {
  background-color: rgba(0, 0, 0, 0.3);
  /* background-color: rgba(0, 0, 0); */
  background-color: #3a4b69;
  width: 100%;
  text-align: left;
  height: 60px;
}

.header-main-menu {
  width: 100%;
  height: calc(100vh - 60px);
  text-align: left;
  /* background-color:#545c64; */
  background-color: rgba(84, 92, 100, 0.4);
}

.header-main-frame {
  width: auto;
  height: calc(100vh - 60px);
}

.header-logo {
  margin-top: 10px;
  width: 300px;
  /* height: 40px; */
  /* line-height: 60px; */
}

.header-left-frame {
  width: 200px;
}

.loader-overlay {
  display: none;
  position: fixed;
  top: 80px;
  left: 10%;
  z-index: 98;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: rgba(0, 0, 0, 0.4);
}

.main-frame {
  /*设置内部填充为0，几个布局元素之间没有间距*/
  padding: 0px;
  /*外部间距也是如此设置*/
  margin: 0px;
  /*统一设置高度为100%*/
  height: 100%;
  /*统一设置宽度为100%*/
  width: 100%;
  /*背景颜色*/
  background-color: white;
  /*最小宽度*/
  min-width: 1200px;
}

/*全局设置tab没有间隔*/
.el-tabs .el-tabs__header {
  margin: 0;
}

.left-menu {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  overflow: hidden;
}

/*更改Elment查看大图关闭按钮颜色*/
.el-icon-circle-close {
  color: white;
}

div .el-dialog__body {
  padding: 0px 20px 20px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}

@keyframes loading-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-moz-keyframes loading-animation /* Firefox */ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loading-animation /* Safari 和 Chrome */ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loading-animation /* Opera */ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gray {
  color: #9e8f8f;
}
.page_header {
  float: left;
}
.el-page-header {
  color: #333333;
  line-height: 60px !important;
}

.el-container .header-main-frame {
  padding: 0;
}

.banner-head {
  margin: 20px;
  background-color: #f1f1f1;
  height: auto !important;
  margin-bottom: 0;
}

.head-info .el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.head-info {
  width: 100%;
  height: 100%;
}

.head-info .el-form-item {
  margin: 10px;
}

.head-info .el-button {
  margin: 10px;
}

.type-form {
  margin: 20px 0 0 20px;
  text-align: left;
  min-width: 600px;
  max-width: 600px;
}

.back {
  color: #333333;
  /* margin-top: 20px; */
}

.footer-info {
  height: 60px;
  text-align: right;
  background: #f1f1f1;
}

.jump_button {
  font-weight: bold;
  text-decoration: none;
}

a {
  color: rgb(49, 49, 224);
}
/* .edit-form {
  margin: 20px 10px 10px 0;
  text-align: left;
  min-width: 600px;
  max-width: 600px;
} */
</style>
